/* eslint-disable */

/** Map component.
 * @Constructor
 * @param [domElem] Optional Map dom element. Default: map-canvas.
 * @param [options] Optional options.
 * @returns
 */
window.MapComponent = function(domElem, options) {

  domElem = domElem || document.getElementById('map-canvas');
  options = options || {};

  // Options
  options.center = options.center || config.CENTER;
  options.zoom = options.zoom || config.ZOOM;
  options.mapTypeId = options.mapTypeId || config.MAP_TYPE;
  options.disableDefaultUI = true;
  options.mapTypeControl = options.mapTypeControl || config.mapTypeControl;
  options.mapTypeControlOptions = {
    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
    position: google.maps.ControlPosition.TOP_RIGHT
  };
  options.zoomControl = false;
  options.zoomControlOptions = {
    style: google.maps.ZoomControlStyle.SMALL,
    position: google.maps.ControlPosition.RIGHT_BOTTOM
  };
  options.scaleControl = true;
  options.streetViewControl = false;
  options.streetViewControlOptions = {
    position: google.maps.ControlPosition.RIGHT_BOTTOM
  };

  var myStyles = [
    {
      featureType:"poi.business",
      elementType:"all",
      stylers:[{
        visibility:"off"
      }]
    },
    {
      featureType:"poi.attraction",
      elementType:"all",
      stylers:[{
        visibility:"off"
      }]
    },
    {
      featureType:"poi.government",
      elementType:"all",
      stylers:[{
        visibility:"off"
      }]
    },
    {
      featureType:"transit",
      elementType:"all",
      stylers:[{
        visibility:"off"
      }]
    }
  ];

  options.styles = myStyles;

  google.maps.Map.call(this, domElem, options);

  var thePanorama = this.getStreetView();

  google.maps.event.addListener(thePanorama, 'visible_changed', function() {

    if (thePanorama.getVisible()) {

      if (config.streetViewOnFunction) {
        try {
          util.callFunctionWithArguments(config.streetViewOnFunction) // fw drupal fuction
        }
        catch (e) {
        }
      }

      if(config.locator.input_id && document.getElementById(config.locator.input_id)) document.getElementById(config.locator.input_id).style.display = "none";

    } else {

      if (config.streetViewOffFunction) {
        try {
          util.callFunctionWithArguments(config.streetViewOffFunction) // fw drupal fuction
        }
        catch (e) {
        }
      }

      if(config.locator.input_id && document.getElementById(config.locator.input_id)) document.getElementById(config.locator.input_id).style.display = "";

    }

  });
}

util.inherits(MapComponent, google.maps.Map);



